import React, {useContext} from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import LanguageContext from "../../contexts/LanguageContext";
import {translations} from "../../translations";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const {isEnglish} = useContext(LanguageContext);
  const currentLang = isEnglish ? translations.en : translations.pt;
  
  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">{currentLang.experience.title}</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: isEnglish ? card.descEn || card.desc : card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: isEnglish ? card.roleEn || card.role : card.role,
                        descBullets: isEnglish ? card.descBulletsEn || card.descBullets : card.descBullets
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
