import React, {useContext} from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import LanguageContext from "../../contexts/LanguageContext";
import {translations} from "../../translations";

export default function Education() {
  const {isDark} = useContext(StyleContext);
  const {isEnglish} = useContext(LanguageContext);
  const currentLang = isEnglish ? translations.en : translations.pt;

  if (educationInfo.display) {
    return (
      <div className="education-section" id="education">
        <h1 className="education-heading">{currentLang.education.title}</h1>
        <div className="education-card-container">
          {educationInfo.schools.map((school, index) => (
            <EducationCard
              key={index}
              isDark={isDark}
              school={{
                schoolName: isEnglish ? school.schoolNameEn || school.schoolName : school.schoolName,
                logo: school.logo,
                subHeader: isEnglish ? school.subHeaderEn || school.subHeader : school.subHeader,
                duration: school.duration,
                desc: isEnglish ? school.descEn || school.desc : school.desc,
                descBullets: isEnglish ? school.descBulletsEn || school.descBullets : school.descBullets
              }}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
