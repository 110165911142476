import codingAnimation from "./assets/lottie/developer.json";
import integrationAnimation from "./assets/lottie/dataflows.json";
import securityAnimation from "./assets/lottie/cybersecurity.json";
import dashboardAnimation from "./assets/lottie/dashboard.json";

export const serviceData = [
  {
    lottieAnimation: codingAnimation,
    name: "Desenvolvimento de Aplicações",
    nameEn: "Application Development",
    desc: "Criação de soluções web e mobile escaláveis e personalizadas, com foco em performance, usabilidade e integração com sistemas corporativos. Tecnologias: React, React Native, JavaScript, TypeScript, Python, Java, Swift, Kotlin.",
    descEn: "Creation of scalable and customized web and mobile solutions, focusing on performance, usability and integration with corporate systems. Technologies: React, React Native, JavaScript, TypeScript, Python, Java, Swift, Kotlin."
  },
  {
    lottieAnimation: integrationAnimation,
    name: "Integração de Sistemas e Dados",
    nameEn: "Systems and Data Integration",
    desc: "Conectividade entre ERPs, CRMs e outras plataformas por meio de APIs modernas, garantindo sincronização de dados e automação de processos. Tecnologias: REST, GraphQL, SOAP, Python, Node.js.",
    descEn: "Connectivity between ERPs, CRMs and other platforms through modern APIs, ensuring data synchronization and process automation. Technologies: REST, GraphQL, SOAP, Python, Node.js."
  },
  {
    lottieAnimation: securityAnimation,
    name: "Cibersegurança e Governança de TI",
    nameEn: "Cybersecurity and IT Governance",
    desc: "Análise, planejamento e implementação de práticas de segurança e conformidade com LGPD, com foco em proteção de dados, prevenção de incidentes e continuidade dos negócios. Tecnologias: Wazuh, GoPhish, MFA, criptografia, protocolos de segurança.",
    descEn: "Analysis, planning and implementation of security practices and LGPD compliance, focusing on data protection, incident prevention and business continuity. Technologies: Wazuh, GoPhish, MFA, cryptography, security protocols."
  },
  {
    lottieAnimation: dashboardAnimation,
    name: "Dashboards e Data Analytics",
    nameEn: "Dashboards and Data Analytics",
    desc: "Desenvolvimento de dashboards estratégicos e visualizações de dados corporativos para insights de negócio. Tecnologias: ETL, Qlik Sense, QlikView, React, Python, PostgreSQL.",
    descEn: "Development of strategic dashboards and corporate data visualizations for business insights. Technologies: ETL, Qlik Sense, QlikView, React, Python, PostgreSQL."
  }
];

