import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import profileImage from "../../assets/images/profile.png";
import { greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import LanguageContext from "../../contexts/LanguageContext";
import { translations } from "../../translations";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  const { isEnglish } = useContext(LanguageContext);
  const currentLang = isEnglish ? translations.en : translations.pt;

  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {" "}
                {currentLang.greeting.title}{" "}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {currentLang.greeting.subtitle}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text={isEnglish ? "Contact me" : "Contato"} href="#contact" />
                {greeting.resumeLink && (
                  <Button
                    text={isEnglish ? "See my resume" : "Meu CV"}
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <img
              alt="Eu"
              src={profileImage}
              className="profile-image"
              style={{ width: "500px" }}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
