export const translations = {
  en: {
    greeting: {
      title: "Hi",
      subtitle: "I'm Mateus Furrier. IT Manager with over 18 years of experience in technical leadership, system development, and enterprise solution integration. Specialist in SAP Business One, Cybersecurity, and Full Stack Development, with strategic focus on adopting innovations to drive business performance.",
      contactMe: "Contact me",
      seeMyResume: "See my resume"
    },
    skills: {
      title: "Tech Stack",
      subtitle: "What I do",
      skills: [
        "⚙️ Technical IT Leadership and Infrastructure Management",
        "💡 Software Architecture with focus on scalable solutions",
        "🌐 SAP B1 and legacy systems integration via REST/SOAP APIs",
        "🔒 Cybersecurity with Wazuh, GoPhish, MFA and LGPD compliance",
        "📊 BI with Qlik Sense, QlikView and ETL processes",
        "☁️ Cloud & DevOps with Docker, Kubernetes, Portainer and CI/CD",
        "🧩 Full stack development with Node.js, Python, React, Next.js"
      ]
    },
    projects: {
      title: "Projects",
      subtitle: "Some Projects Developed By Me",
      moreProjects: "More Projects"
    },
    education: {
      title: "Education",
      subtitle: "My Academic Background"
    },
    experience: {
      title: "Experience",
      subtitle: "Work Experience"
    },
    contact: {
      title: "Contact ☎️",
      subtitle: "Want to discuss a project or just say hi? My inbox is open for all."
    },
    footer: {
      madeWith: "Made with ❤️ by Mateus Furrier",
      
    }
  },
  pt: {
    greeting: {
      title: "Olá",
      subtitle: "Sou Mateus Furrier. IT Manager com mais de 18 anos de experiência em liderança técnica, desenvolvimento de sistemas e integração de soluções empresariais. Especialista em SAP Business One, Cibersegurança e Desenvolvimento Full Stack, com atuação estratégica na adoção de inovações para impulsionar a performance do negócio.",
      contactMe: "Entre em contato",
      seeMyResume: "Veja meu currículo"
    },
    skills: {
      title: "Tech Stack",
      subtitle: "O que eu faço",
      skills: [
        "⚙️ Liderança técnica em TI e Gestão de Infraestrutura",
        "💡 Arquitetura de software com foco em soluções escaláveis",
        "🌐 Integrações com SAP B1 e sistemas legados via APIs REST/SOAP",
        "🔒 Cibersegurança com Wazuh, GoPhish, MFA e conformidade LGPD",
        "📊 BI com Qlik Sense, QlikView e processos ETL",
        "☁️ Cloud & DevOps com Docker, Kubernetes, Portainer e CI/CD",
        "🧩 Desenvolvimento full stack com Node.js, Python, React, Next.js"
      ]
    },
    projects: {
      title: "Projetos",
      subtitle: "Alguns Projetos Desenvolvidos Por Mim",
      moreProjects: "Mais Projetos"
    },
    education: {
      title: "Educação",
      subtitle: "Minha Formação Acadêmica"
    },
    experience: {
      title: "Experiência",
      subtitle: "Experiência Profissional"
    },
    contact: {
      title: "Contato ☎️",
      subtitle: "Quer discutir um projeto ou apenas dizer oi? Minha caixa de entrada está aberta para todos."
    },
    footer: {
      madeWith: "Feito com ❤️ por Mateus Furrier",
      
    }
  }
}; 